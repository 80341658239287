import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ListaSociedadResponseDTO } from 'src/app/data/dto/SociedadDTO';
import { SociedadRepository } from '../../repositories/sociedad/SociedadRepository';

@Injectable({
    providedIn: 'root'
})
export class ListaSociedadesUseCase implements UseCase<string, ListaSociedadResponseDTO> {
    constructor(private sociedadRepository: SociedadRepository) { }

    execute(): Observable<ListaSociedadResponseDTO> {
        return this.sociedadRepository.listaSociedades();
    }
}
