import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ListaAreasDepartamentosResponseDTO } from 'src/app/data/dto/AreaDepartamentoDTO';
import { AreaDepartamentoRepository } from '../../repositories/AreaDepartamento/AreaDepartamentoRepository';

@Injectable({
    providedIn: 'root'
})
export class ListaAreaDepartamentoUseCase implements UseCase<string, ListaAreasDepartamentosResponseDTO> {
    constructor(private areaDepartamentoRepository: AreaDepartamentoRepository) { }

    execute(): Observable<ListaAreasDepartamentosResponseDTO> {
        return this.areaDepartamentoRepository.listaAreasDepartamentos();
    }
}
