import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { SolicitudRepository } from '../../repositories/Solicitud/SolicitudRepository';
import { VisorFacturaXMLRequestDTO,
         VisorFacturaXMLResponseDTO
        } from 'src/app/data/dto/SolicitudDTO';

@Injectable({
    providedIn: 'root'
})
export class VisorFacturaXMLUseCase
    implements UseCase<VisorFacturaXMLRequestDTO, VisorFacturaXMLResponseDTO> {
    constructor(private solicitudRepository: SolicitudRepository) { }

    execute(request: VisorFacturaXMLRequestDTO): Observable<VisorFacturaXMLResponseDTO> {
        return this.solicitudRepository.visorFacturaXML(request);
    }
}
