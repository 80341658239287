import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ListaEstatusSolicitudesResponseDTO } from 'src/app/data/dto/EstatusSolicitudesDTO';
import { EstatusSolicitudesRepository } from '../../repositories/EstatusSolicitudes/EstatusSolicitudesRepository';

@Injectable({
    providedIn: 'root'
})

export class ListaEstatusSolicitudesUseCase implements UseCase<string, ListaEstatusSolicitudesResponseDTO> {
    constructor(private estatusSolicitudesRepository: EstatusSolicitudesRepository) { }

    execute(): Observable<ListaEstatusSolicitudesResponseDTO> {
        return this.estatusSolicitudesRepository.listaEstatusSolicitudes();
    }
}
