import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ListImpuestosResponseDTO } from 'src/app/data/dto/ImpuestosDTO';
import { ImpuestosRepository } from '../../repositories/Impuestos/ImpuestosRepository';

@Injectable({
    providedIn: 'root'
})
export class ListaImpuestosUseCase implements UseCase<string, ListImpuestosResponseDTO> {
    constructor(private impuestosRepository: ImpuestosRepository) { }

    execute(): Observable<ListImpuestosResponseDTO> {
        return this.impuestosRepository.listaImpuestos();
    }
}
