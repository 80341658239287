import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ListaMonedaResponseDTO } from 'src/app/data/dto/MonedaDTO';
import { MonedaRepository } from '../../repositories/moneda/MonedaRepository';

@Injectable({
    providedIn: 'root'
})
export class ListaMonedasUseCase implements UseCase<string, ListaMonedaResponseDTO> {
    constructor(private monedaRepository: MonedaRepository) { }

    execute(): Observable<ListaMonedaResponseDTO> {
        return this.monedaRepository.listaMonedas();
    }
}
