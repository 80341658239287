import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ListaMotivosRechazoResponseDTO } from 'src/app/data/dto/MotivosRechazoDTO';
import { MotivosRechazoRepository } from '../../repositories/MotivosRechazo/MotivosRechazoRepository';

@Injectable({
    providedIn: 'root'
})

export class ListaMotivosRechazoUseCase implements UseCase<string, ListaMotivosRechazoResponseDTO> {
    constructor(private motivosRechazoRepository: MotivosRechazoRepository) { }

    execute(): Observable<ListaMotivosRechazoResponseDTO> {
        return this.motivosRechazoRepository.listaMotivosRechazo();
    }
}
