import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AutorizadoresAreaRepository } from '../../repositories/AutorizadoresArea/AutorizadoresAreaRepository';
import { ListaAutorizadoresAreaResponseDTO } from 'src/app/data/dto/AutorizadoresAreaDTO';

@Injectable({
    providedIn: 'root'
})
export class ListaAutorizadoresAreaUseCase implements UseCase<string, ListaAutorizadoresAreaResponseDTO> {
    constructor(private autorizadoresAreaRepository: AutorizadoresAreaRepository) { }

    execute(): Observable<ListaAutorizadoresAreaResponseDTO> {
        return this.autorizadoresAreaRepository.listaAutorizadoresArea();
    }
}
