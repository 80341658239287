import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CambioEstatusSolicitudesRepository } from '../../repositories/CambioEstatusSolicitudes/CambioEstatusSolicitudesRepository';
import { CambiarEstatusSolicitudAutorizadaRequestDTO,
         CambiarEstatusSolicitudAutorizadaResponseDTO
        } from 'src/app/data/dto/CambioEstatusSolicitudDTO';

@Injectable({
    providedIn: 'root'
})
export class CambiarEstatusSolicitudAutorizadaUseCase
    implements UseCase<CambiarEstatusSolicitudAutorizadaRequestDTO, CambiarEstatusSolicitudAutorizadaResponseDTO> {
    constructor(private cambioEstatusSolicitudesRepository: CambioEstatusSolicitudesRepository) { }

    execute(request: CambiarEstatusSolicitudAutorizadaRequestDTO): Observable<CambiarEstatusSolicitudAutorizadaResponseDTO> {
        return this.cambioEstatusSolicitudesRepository.cambiarEstatusSolicitudAutorizada(request);
    }
}
