import { UseCase } from '../../base/UserCase';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CambioEstatusSolicitudesRepository } from '../../repositories/CambioEstatusSolicitudes/CambioEstatusSolicitudesRepository';
import { RechazoEstatusSolicitudAutorizadaRequestDTO,
         RechazoEstatusSolicitudAutorizadaResponseDTO
        } from 'src/app/data/dto/CambioEstatusSolicitudDTO';

@Injectable({
    providedIn: 'root'
})
export class RechazoEstatusSolicitudAutorizadaUseCase
    implements UseCase<RechazoEstatusSolicitudAutorizadaRequestDTO, RechazoEstatusSolicitudAutorizadaResponseDTO> {
    constructor(private cambioEstatusSolicitudesRepository: CambioEstatusSolicitudesRepository) { }

    execute(request: RechazoEstatusSolicitudAutorizadaRequestDTO): Observable<RechazoEstatusSolicitudAutorizadaResponseDTO> {
        return this.cambioEstatusSolicitudesRepository.rechazoEstatusSolicitudAutorizada(request);
    }
}
