import { Component, TemplateRef, HostBinding } from '@angular/core';
import { ToastService } from 'src/app/service/toast.service';

@Component({
  selector: 'app-toasts-global',
  template: `
    <ngb-toast
      *ngFor="let toast of toastService.toasts"
      [header]="toast.headertext"
      [class]="toast.classname"
      [autohide]="toast.autohide"
      [delay]="10000"
      (hide)="toastService.remove(toast)"
    >
      <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ toast.textOrTpl }}</ng-template>
    </ngb-toast>
  `,

})

export class ToastsGlobalComponent {

  @HostBinding('class.ngb-toasts')
  ngbToasts = true;

  constructor(public toastService: ToastService) {}

  isTemplate(toast): boolean {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
